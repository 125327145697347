export default [
  {
    name: 'HOME',
    url: '/'
  },
  {
    name: 'ABOUT',
    url: '/a'
  },
  {
    name: 'TWITTER',
    url: 'https://twitter.com/jikantoki'
  },
  {
    name: '404',
    url: '/hf8wehegiaohiedwhie'
  }
]
